<template>
    <section class="viviendasIndex ml-4">
        <tabs :tabs="arrayTabs" />
        <router-view class="mt-4" @refrescar="listarIndicadores" />
    </section>
</template>

<script>
import Viviendas from '~/services/viviendas'

export default {
    components:{
    // modalRegistrarPago: () => import('./partials/modalRegistrarPago')
    },
    data(){
        return{
            inputSearch:'',
            arrayTabs: [
                {
                    titulo:'Viviendas',
                    ruta:"viviendas.sistema",
                },
                {
                    titulo:'Residentes',
                    ruta:"viviendas.residentes",
                },
                {
                    titulo:'Solicitudes de residentes',
                    ruta:"viviendas.solicitudes",
                    num: 0
                },
            ],
        }
    },
    mounted(){
        this.listarIndicadores()
    },
    methods:{
        async listarIndicadores(){
            try {
                const { data } = await Viviendas.indicadoresGenerales()
                this.arrayTabs[2].num = data.solicitudes
            } catch (error){
                this.errorCatch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    
</style>